export type ThemeProps = {
  primary: string;
  secondary?: string;
  background?: string;
  "secondary-background"?: string;
  "darker-primary"?: string;
  "lighter-primary"?: string;
  "primary-text"?: string;
  "secondary-text"?: string;
  "chat-primary"?: string;
  "chat-secondary"?: string;
  "chat-background"?: string;
  "chat-consent-bg"?: string;
  "chat-progress-bar-bg"?: string;
  "input-invalid-color"?: string;
  "input-placeholder-color"?: string;
};

export type ThemeKeys = "default" | "empathyiq";

export type Themes = {
  [key in ThemeKeys]: ThemeProps;
};

export type ThemeName = keyof Themes;

const themes: Themes = {
  default: {
    primary: "#096846",
    background: "#FFFFFF",
    "secondary-background": "#F1F5F9",
    "darker-primary": "#075539",
    "lighter-primary": "#0968472a",
    secondary: "#96C89E",
    "primary-text": "#2A3547",
    "secondary-text": "#5A6A85",
    // chat
    "chat-primary": "#FF6F98",
    "chat-secondary": "#FFE8ED",
    "chat-background": "#F3F4F6",
    "chat-consent-bg": "#FF6F98",
    "chat-progress-bar-bg": "#FF6F98",
    // input
    "input-invalid-color": "#e5e7eb",
    "input-placeholder-color": "#9ca3af",
  },

  empathyiq: {
    primary: "#ACD434",
    background: "#363F47",
    "secondary-background": "#575b5fff",
    "darker-primary": "#94ba25",
    "lighter-primary": "#c9f1525f",
    secondary: "#cfeb7c",
    "primary-text": "#FFFFFF",
    "secondary-text": "#ffffffed",
    // chat
    "chat-primary": "#94ba25",
    "chat-secondary": "#c9f1525f",
    "chat-background": "#363F47",
    "chat-consent-bg": "#363F47",
    "chat-progress-bar-bg": "#dbfa7d",
    // input
    "input-invalid-color": "#e5e7eb",
    "input-placeholder-color": "#9ca3af",
  },
};

export default themes;
